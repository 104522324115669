import { useState } from 'react';
import { Radio, Table } from '../../../../../components/UI';
import s from './Question2.module.scss';
import { PERCENT_COUNT_TYPES } from '../../../../../constants/names';

const infoTypes = Object.keys(PERCENT_COUNT_TYPES);

const tableHeaders = [
  { key: 'answer', name: 'Answers', width: '50%' },
  { key: '1', name: '1st', width: '12.5%' },
  { key: '2', name: '2nd', width: '12.5%' },
  { key: '3', name: '3rd', width: '12.5%' },
  { key: '4', name: '4th', width: '12.5%' },
];

const Question2 = ({ data }) => {
  const [ infoType, setInfoType ] = useState(infoTypes[0]);

  return (
    <div>
      <div className={s.tableInfoTypes}>
        {infoTypes.map(type => (
          <div onClick={() => setInfoType(type)} key={type}>
            <div className={s.radiolable}>{PERCENT_COUNT_TYPES[type]}</div>
            <div>
              <Radio active={infoType === type} size='18px' />
            </div>
          </div>
        ))}
      </div>
      <Table>
        <Table.Header>
          <Table.HeaderCell className={s.tableHeader} width={'50%'}>
            <div>Answer</div>
          </Table.HeaderCell>
          {tableHeaders.map((header, i) => {
            if (!i) return null;
            return (
              <Table.HeaderCell
                key={header.key}
                className={s.tableHeader}
                width={header.width}>
                <div className={s.rankHeader}>
                  <div>{header.name}</div>
                  <div>Rank</div>
                </div>
              </Table.HeaderCell>
            );
          })}
        </Table.Header>
        <Table.Body>
          {data.map((info, i) => (
            <Table.Row key={i}>
              <Table.Cell width='50%'>{info.value}</Table.Cell>
              {tableHeaders.map((item, idx) => {
                if (!idx) return null;
                return (
                  <Table.Cell className={s.tableRow} width={item.width} key={item.key}>
                    {infoType === infoTypes[2]
                      ? <div className={s.both}>
                        {/* <div>{info[item.key][infoTypes[0]]}%</div>
                        <div>{info[item.key][infoTypes[1]]}</div> */}
                        <div>{`${info[infoTypes[1]][item.key]} - ${info[infoTypes[0]][item.key]}%`}</div>
                      </div>
                      : <div>{`${info[infoType][item.key]}${infoType === infoTypes[0] ? '%' : ''}`}</div>}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Question2;
