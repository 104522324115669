import { useLocation } from 'react-router-dom';
import { getHeadingTitle } from '../../helpers/utils';
import s from './Heading.module.scss';

const Heading = ({ children }) => {
  const location = useLocation();
  const headingTitle = getHeadingTitle(location.pathname);

  return (
    <div className={s.heading}>
      <div className={s.headingTitle}>{headingTitle}</div>
      {children
      && <div className={s.actions}>
        {children}
        </div>}
    </div>
  );
};

export default Heading;
