export const PATIENT_BASE_INFO_KEYS = {
  GENDER: 'gender',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  AGE: 'age',
  VISIT: 'patientVisit',
  SYMPTOMS: 'symptoms',
  HISTORY: 'history',
  VISIT_KEY: 'patientVisitLabel',
  SYMPTOMS_KEY: 'symptomsLabel',
  HISTORY_KEY: 'historyLabel',
};

const {
  GENDER, FIRST_NAME, LAST_NAME, AGE, VISIT,
} = PATIENT_BASE_INFO_KEYS;

export const PATIENTS_SORT_OPTIONS = [
  { value: FIRST_NAME, label: 'First Name' },
  { value: LAST_NAME, label: 'Last Name' },
  { value: AGE, label: 'Age' },
  { value: GENDER, label: 'Gender' },
  { value: VISIT, label: 'Visit' },
];
