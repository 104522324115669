import { Progress } from '../../../../../components/UI';
import s from './Question1.module.scss';

const Question1 = ({ data, comments }) => (
  <div className={s.question1Root}>
    <div className={s.question1Content}>
      {data.map((item, i) => (
        <div key={i} className={s.questionItem}>
          <div className={s.questionTitle}>{item.value}</div>
          <div className={s.questionInfo}>
            <div><Progress percent={item.percentage || 0} /></div>
            <div>{item.percentage || 0}%</div>
          </div>
        </div>
      ))}
      {comments
      && <div className={s.commentsBoard}>
        <div className={s.commentHeader}>
          <div>Write-In Answers:</div><div>Count: {comments.length}</div>
        </div>
        {comments.map((comment, i) => (
          <div key={i} className={s.commentItem}>{comment}</div>
        ))}
        </div>
      }
    </div>
  </div>
);

export default Question1;
