import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { resetFilteredEvents } from '../../redux/slices/filteredEventsSlice';
import { resetUser, selectIsAuth } from '../../redux/slices/userInfoSlice';
import s from './Menu.module.scss';
import MedQP_Logo from '../../assets/nImages/MedQP_Logo.svg';

const Menu = ({ validRoutes }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const [ activeMenuItems, setActiveMenuItems ] = useState([ 'Events', 'Patient Cases', 'Overall Analysis', 'Data Analysis' ]);

  const onDropdownHandler = (name) => {
    if (activeMenuItems.includes(name)) {
      return setActiveMenuItems(activeMenuItems.filter(item => item !== name));
    }
    return setActiveMenuItems(prev => [ ...prev, name ]);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    dispatch(resetUser());
    dispatch(resetFilteredEvents());
  };

  return (
    <aside className={s.menu}>
      <div className={s.menuHolder}>
        <div className={s.headerLogo__icon}>
          <img className={s.headerLogo__svg} src={MedQP_Logo} alt="MedQP" />
        </div>
      </div>
      <section className={s.menuContainer}>
        <div className={s.menuNavbar}>
          {isAuth && validRoutes.map((route, i) => {
            if (!route.multi) {
              return (
                <div key={route.name} className={s.menuTitle}>
                  <NavLink activeClassName={s.activeItem} to={route.path} className={s.menuItem}>
                    {route.name}
                  </NavLink>
                </div>
              );
            }
            return (
              <div
                key={route.name}
                className={s.dropdown}>
                <div
                  onClick={() => onDropdownHandler(route.name)}
                  className={classNames(s.menuItem, s.dropdownTitle)}>
                  <div >{route.name}</div>
                  <div className={classNames(s.arrow,
                    { [s.arrowActive]: activeMenuItems.includes(route.name) })} />
                </div>
                {activeMenuItems.includes(route.name) && (
                <div className={s.multiItems}>
                  {route.childs.map((child, idx) => (
                    <NavLink
                      key={child.name}
                      className={classNames(s.menuItem, s.dropdownMenuItem)}
                      activeClassName={s.activeItem}
                    // onClick={() => setActiveDropdown(null)}
                      to={route.path + child.path}>
                      {child.name}
                    </NavLink>
                  ))}
                </div>
                )}
              </div>
            );
          })}
        </div>
        {isAuth && <div onClick={signOut} className={s.signOut}>
          <div>Sign out</div>
        </div>
        }
        <div className={s.menuGreenBubble} />
      </section>
    </aside>
  );
};

export default Menu;
