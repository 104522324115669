import { createSlice } from '@reduxjs/toolkit';
import { satisfactionExtraReducers } from '../thunks/satisfactionThunk';

const initialState = {
  analys: null,
  isLoading: false,
};

export const satisfactionSlice = createSlice({
  name: 'satisfactionAnalys',
  initialState,
  reducers: {
    addSatisfactionAnalys: (state, { payload }) => { state.analys = payload; },
    resetSatisfactionAnalys: () => initialState,
    setSatisfactionLoading: (state, { payload }) => { state.isLoading = payload; },
  },
  extraReducers: satisfactionExtraReducers,
});

// ACTIONS
export const {
  addSatisfactionAnalys, resetSatisfactionAnalys, setSatisfactionLoading,
} = satisfactionSlice.actions;

// SELECTORS
export const selectSatisfactionAnalys = state => state.satisfactionAnalys.events;

export default satisfactionSlice.reducer;
