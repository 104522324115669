import { createSlice } from '@reduxjs/toolkit';
import { reportAnalysExtraReducers } from '../thunks/reportAnalysThunk';

const initialState = {
  analys: null,
  isLoading: false,
};

export const reportAnalysSlice = createSlice({
  name: 'reportAnalys',
  initialState,
  reducers: {
    addReportAnalys: (state, { payload }) => { state.analys = payload; },
    resetReportAnalys: () => initialState,
    setReportLoading: (state, { payload }) => { state.isLoading = payload; },
  },
  extraReducers: reportAnalysExtraReducers,
});

// ACTIONS
export const { addReportAnalys, resetReportAnalys, setReportLoading } = reportAnalysSlice.actions;

// SELECTORS
export const selectReportAnalys = state => state.reportAnalys.events;

export default reportAnalysSlice.reducer;
