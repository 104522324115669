import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './AddPatientModal.module.scss';
import PatientItem from '../../../../../PatientCases/components/PatientItem/PatientItem';
import { Button } from '../../../../../../components/UI';
import Modal from '../../../../../../components/UI/Modal/Modal';
import PatientItemLoader from '../../../../../PatientCases/components/PatientItem/PatientItemLoader';
import { useInfiniteScroll } from '../../../../../../hooks/useInfiniteScroll';
import NoItem from '../../../../../../components/NoItem/NoItem';
import { resetPatients } from '../../../../../../redux/slices/patientsSilce';
import arrowDown from '../../../../../../assets/images/arrow.svg';

const AddPatientModal = ({
  closeModal, patientCases, onOkClick, fetchCases,
}) => {
  const dispatch = useDispatch();
  const { patients, isLoading } = useSelector(state => state.patientsData);
  const [ activePatients, setActivePatients ] = useState([]);
  const [ checkedPatients, setCheckedPatients ] = useState(patientCases);

  const { lastTicketRef, scrollLoading } = useInfiniteScroll(
    page => fetchCases(page),
  );

  useEffect(() => () => dispatch(resetPatients()), []);

  const onEventClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  const onPatientChecked = (patient) => {
    if (checkedPatients.find(item => item.id === patient.id)) {
      return setCheckedPatients(checkedPatients.filter(el => el.id !== patient.id));
    }
    return setCheckedPatients(prev => [ ...prev, patient ]);
  };

  return (
    <Modal className={s.modal}>
      <div className={s.innerModal}>
        <div className={s.header}>
          <div>Add Patient Case</div>
        </div>
        <div className={s.content}>
          {isLoading
            ? Array(1).fill(null).map((_, idx) => (
              <PatientItemLoader key={idx} check />
            ))
            : patients.map((patient, i) => {
              const isChecked = !!checkedPatients.find(item => item.id === patient.id);
              const nextCase = patient.nextCase && patients.find(item => item.caseId === patient.nextCase);
              const isNextChecked = !!patient.nextCase && checkedPatients.find(item => item.caseId === patient.nextCase);

              return (
                <div key={i}>
                  <PatientItem
                    check
                    checked={isChecked}
                    onCheck={() => onPatientChecked(patient)}
                    key={patient.id}
                    lastTicketRef={i === patients.length - 1 ? lastTicketRef : null}
                    patient={patient}
                    isActive={activePatients.includes(patient.id)}
                    onEventClick={() => onEventClick(patient.id)}
                  />
                  {isChecked && nextCase && !isNextChecked && (
                    <div className={s.nextSelection}>
                      <div className={s.nextSelectText}>do you want to select the next visit?</div>
                      <div className={s.nextSelectButton}>
                        <Button onClick={() => onPatientChecked(nextCase)}>
                          <div className={s.buttonContext}>
                            <div>Select</div>
                            <div className={s.arrowBottom}>
                              <img src={arrowDown} alt='' />
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
      }
          {(!isLoading && !patients.length) && <NoItem title='Patient' />}
          {scrollLoading && <PatientItemLoader />}
        </div>
        <div className={s.actions}>
          <Button onClick={closeModal} variant='light'>Cancel</Button>
          <Button
            disabled={checkedPatients.length === 0}
            onClick={() => onOkClick(checkedPatients)}>OK</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPatientModal;
