import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/Heading/Heading';
import FilterCard from '../../../components/FilterCard/FilterCard';
import s from './CustomerSatisfaction.module.scss';
import SatisfactionItem from './components/SatisfactionItem/SatisfactionItem';
import { getSatisfactionAnalysThunk } from '../../../redux/thunks/satisfactionThunk';
import NoItem from '../../../components/NoItem/NoItem';
import { resetSatisfactionAnalys } from '../../../redux/slices/satisfactionSlice';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';
import { EVENT_KEYS } from '../../../constants/names';

const CustomerSatisfaction = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const { analys, isLoading } = useSelector(state => state.satisfactionAnalys);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.cases = filterCases;
      }
      dispatch(getSatisfactionAnalysThunk(data));
    }
    return () => {
      dispatch(resetSatisfactionAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.customerSatisfaction}>
      <Heading reset={resetSatisfactionAnalys}>
        {!isModerator && <FilterCard />}
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              <div className={s.satisfactionBoard}>
                {!isLoading && (analys && analys.doctors
                  ? <>
                    <div className={s.satisfactionItem}>
                      <SatisfactionItem
                        doctors={analys.doctors}
                        rate={analys.rate}
                        percentage={analys.ratePercentage}
                        title='Patient Case Satisfaction' />
                    </div>
                    <div className={s.satisfactionItem}>
                      <SatisfactionItem
                        doctors={analys.doctors}
                        rate={analys.appRate}
                        percentage={analys.appRatePercentage}
                        title='Application Satisfaction' />
                    </div>
                  </>
                  : <div className={s.noEvent}><NoItem analys /></div>)
                  }
              </div>
              {analys && analys.feedback && <div className={s.commentsBoard}>
                  { analys.feedback.map((comment, idx) => (
                    <div className={s.comment} key={idx}>{comment}</div>
                  ))}
              </div>}
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default CustomerSatisfaction;
