import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CarouselPopup from '../../../../../components/CarouselPopup/CarouselPopup';
import PhotoPopup from '../../../../../components/PhotoPopup/PhotoPopup';

import { Table } from '../../../../../components/UI';
import CheckboxPure from '../../../../../components/UI/Checkbox/CheckboxPure';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import VideoPopup from '../../../../../components/VideoPopup/VideoPopup';
import { getTop10Items } from '../../../../../helpers/utils';
import s from './TableView.module.scss';

const TableView = ({ data, isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const videos = useSelector(state => state.reportAnalys.analys.videos);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const getVideoPaths = name => videos.find(item => item.name === name) || null;
  const { answers, answersCategories } = data;
  const allData = useMemo(() => {
    const items = [];
    Object.values(answers).forEach((item) => {
      item.forEach(el => items.push(el));
    });
    return items;
  }, [ answers ]);
  const keys = Object.keys(answers);
  const [ isDropdownActive, setIsDropdownActive ] = useState(false);
  const [ checkedKeys, setCheckedKeys ] = useState(keys);

  useEffect(() => {
    setCheckedKeys(keys);

    return () => {
      setCheckedKeys([]);
    };
  }, [ data ]);

  const isAllChecked = keys.length === checkedKeys.length;
  const filteredData = useMemo(() => (
    allData.filter(item => checkedKeys.includes(item[answersCategories[0]]))
  ), [ allData, checkedKeys ]);

  const finalData = useMemo(() => (
    isOnlyTop ? getTop10Items(filteredData) : filteredData
  ), [ filteredData, isOnlyTop ]);

  const onSelectAll = () => {
    if (isAllChecked) {
      setCheckedKeys([]);
    } else {
      setCheckedKeys(keys);
    }
  };

  const onDropItemClick = (key) => {
    if (checkedKeys.includes(key)) {
      setCheckedKeys(prev => prev.filter(item => item !== key));
    } else {
      setCheckedKeys(prev => [ ...prev, key ]);
    }
  };

  return (
    <div className={s.tableViewContainer}>
      <Table>
        <Table.Header>
          {answersCategories.map((header, i) => (
            <Table.HeaderCell
              key={header}
              className={s.tableHeader}
              length={answersCategories.length}>
              <div className={s.headerCell}>
                <div>{header}</div>
                {i === 0
                && <div
                  onClick={() => setIsDropdownActive(prev => !prev)}
                  className={s.dropdownIconWrapper}>
                  <div
                    className={
                    classNames(s.dropdownIcon, { [s.dropdownIconActive]: isDropdownActive })} />
                  </div>}
              </div>
            </Table.HeaderCell>
          ))}
          <Dropdown
            rootClass={s.dropdown}
            toggle={setIsDropdownActive}
            active={isDropdownActive}
            onlyModal>
            <Dropdown.Modal className={s.dropdownModal}>
              <div className={s.dropdownList}>
                {!!keys.length && (
                <div onClick={onSelectAll} className={classNames(s.dropSelectAll, s.dropdownItem)}>
                  <div>Select All</div>
                  <div><CheckboxPure checked={isAllChecked} /></div>
                </div>
                )}
                {keys.map(key => (
                  <div onClick={() => onDropItemClick(key)} key={key} className={s.dropdownItem}>
                    <div>{key}</div>
                    <div><CheckboxPure
                      checked={checkedKeys.includes(key)}
                       /></div>
                  </div>
                ))}
              </div>
            </Dropdown.Modal>
          </Dropdown>
        </Table.Header>
        <Table.Body>
          {finalData.map(row => (
            <Table.Row key={row.id}>
              {answersCategories.map((item) => {
                if (item === 'IMAGE') {
                  const image = row[item];
                  const isImage = row[item].split(',').length === 1;
                  const isImages = row[item].split(',').length > 1;
                  let imageFile;
                  let imageFiles;
                  if (isImage) {
                    imageFile = getPhotoPath(row[item]);
                  }
                  if (isImages) {
                    imageFiles = row[item].split(',').map(i => i.trim()).map(i => getPhotoPaths(i));
                  }
                  return <Table.Cell
                    key={item}
                    length={answersCategories.length}
                    className={classNames(s.tableRow, { [s.pointer]: image })}>
                    { isImage && <div className={classNames({ [s.imageView]: image })}>
                      {
                        <div onClick={() => image && setShowPhoto(imageFile)}>{image ? 'View' : '-'}</div>
                      }
                      </div>}
                    {isImages && <div className={classNames({ [s.imageView]: image })}>
                      {
                        <div onClick={() => imageFiles && setShowPhotos(imageFiles)}>{imageFiles ? 'View' : '-' }</div>
                      }
                      </div>}
                  </Table.Cell>;
                }
                if (item === 'VIDEO') {
                  const video = row[item];
                  const videoFile = getVideoPaths(row[item]);
                  return <Table.Cell
                    key={item}
                    length={answersCategories.length}
                    className={classNames(s.tableRow, { [s.pointer]: video })}>
                    <div onClick={() => videoFile && setShowVideo(getVideoUrl(videoFile))}>{videoFile ? 'View' : '-' }</div>
                  </Table.Cell>;
                }
                return <Table.Cell
                  className={s.tableRow}
                  length={answersCategories.length}
                  key={item}>
                  <div>
                    {![ null, undefined ].includes(row[item]) ? String(row[item]).replace('***', '').split('\n').map((paragraph, i) => (
                      <p key={i} className='paragraph'>{paragraph}</p>
                    )) : '-'}
                  </div>
                </Table.Cell>;
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showPhotos
        && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );

  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }

  function getVideoUrl(video) {
    return { videoUrl: process.env.REACT_APP_IMAGE_URL + video.videoUrl };
  }
};

export default TableView;
