import classNames from 'classnames';
import s from './SideBar.module.scss';

const SideBar = ({ open, children }) => (
  <div className={classNames(s.sidebar, { [s.sidebarActive]: open })}>
    {children}
  </div>
);

SideBar.Item = ({
  open, active, icon, text, toggleSidebar, onClick,
}) => (
  <div onClick={onClick} className={classNames(s.sidebarItem, { [s.activeItem]: active })}>
    <div className={s.sidebarIcon}>
      <div>
        {icon
        && <img
          className={classNames(s.icon, { [s.active]: active })}
          src={`${process.env.REACT_APP_IMAGE_URL}images/${icon}`}
          alt='' />}
      </div>
    </div>
    {open && <div className={s.sidebarText}>{text}</div>}
    {active && <div onClick={toggleSidebar} className={s.sidebarArrow}>
      <div className={classNames(s.arrow, { [s.rightArrow]: !open })} />
    </div>}
  </div>
);

export default SideBar;
