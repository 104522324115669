import { TEMPLATE_TYPES } from '../constants/names';
import Physical from '../pages/CaseEventAnalysis/Report/Templates/Physical/Physical';
import TableView from '../pages/CaseEventAnalysis/Report/Templates/TableView/TableView';
import MultipleChoice from '../pages/CaseEventAnalysis/Report/Templates/MultipleChoice/MultipleChoice';
import Question2 from '../pages/CaseEventAnalysis/QuestionsAnswers/components/Question2/Question2';

const {
  IMAGE_INTERACTIVE,
  MULTIPE_CHOICE,
  RANKING,
  TABLE_VIEW,
  INTERVIEW,
  TABLE_VIEW_GROUPS,
  MULTIPLE_CHOICE_GROUPS,
} = TEMPLATE_TYPES;

export const REPORT_TEMPLATE_CONFIGS = {
  [INTERVIEW]: {
    Component: TableView,
  },
  [TABLE_VIEW_GROUPS]: {
    Component: TableView,
  },
  [MULTIPLE_CHOICE_GROUPS]: {
    Component: TableView,
  },
  [TABLE_VIEW]: {
    Component: MultipleChoice,
  },
  [MULTIPE_CHOICE]: {
    Component: MultipleChoice,
  },
  [IMAGE_INTERACTIVE]: {
    Component: Physical,
  },
  [RANKING]: {
    Component: Question2,
  },
};
